
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IMaker } from "@/types/maker";
import ProductList from "@/components/admin/list/ProductList.vue";

@Component({
  components: { ProductList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Action("maker/adminGet")
  public getMaker!: (id: number) => void;

  @Getter("maker/single")
  public maker!: IMaker;

  @Mutation("maker/clear")
  public clear!: () => void;

  // fields
  public maker_id = 0;
  public items: { text: string; icon: string; to: string }[] = [];

  public async created() {
    this.maker_id = Number(this.$route.params.maker_id);
    this.items = [
      {
        text: "商品",
        icon: "mdi-package-variant-closed",
        to: `/admin/maker/${this.maker_id}/product`
      }
    ];

    this.clear();
    await Promise.all([this.getMaker(this.maker_id)]);
  }
}
